import { Hit as AlgoliaHit } from "@algolia/client-search";
import { BaseHit } from "instantsearch.js/es/types";
import { useContext, useEffect, useRef } from "react";
import { useInfiniteHits, UseInfiniteHitsProps } from "react-instantsearch";
import SearchResultItem from "../../components/items/SearchResultItem";
import { FleetContext } from "../../contexts/FleetContext";
import { Distinct } from "../../helpers/Array";
import { IProductSearchResult } from "../../models/product/IProductSearchResult";
import { MachineName } from "../../queries/FleetQuery";
import { AvailabilityStatusEnum } from "../../queries/ProductPageQuery";
import { useStats } from "./AlgoliaHelpers";
import styles from "./Search.module.scss";

type InfiniteHitsProps<THit> = React.ComponentProps<"div"> &
  UseInfiniteHitsProps & {
    hits: (props: { hit: THit }) => JSX.Element;
  };

export interface ProductMaster {
  fitment: string[];
  interchange: string[];
  model: string[];
  objectID: string;
  part: string;
  category: string;
  title: string;
  sku: string;
  stock: AvailabilityStatusEnum;
  quantity: number;
  city: string;
  state: string;
  url: string;
  picture: string;
  price: number;
}

export function InstantSearchResults<THit extends AlgoliaHit<Record<string, unknown>>>({
  cache: sessionStorageCache,
}: InfiniteHitsProps<THit>) {
  const { hits, isLastPage, showMore, sendEvent } = useInfiniteHits<BaseHit & ProductMaster>({
    cache: sessionStorageCache as any,
    escapeHTML: false,
  });
  const fleetContext = useContext(FleetContext);
  const sentinelRef = useRef(null);
  const { query } = useStats();

  const selectedMachine = MachineName(fleetContext.selectedMachine);
  const LowerSelectedMachine = selectedMachine.toLowerCase();

  const lowerdQueryWords = query
    .toLowerCase()
    .split(" ")
    .filter((x) => !!x);

  const queryWords = query.split(" ").filter((x) => !!x);

  // Load initial results
  useEffect(() => {
    if (!isLastPage) {
      showMore && showMore();
    }
  }, [showMore, isLastPage]);

  // Infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore && showMore();
        }
      });
    });

    const currentSentinel = sentinelRef.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [isLastPage, showMore]);

  return (
    <div className={styles.searchContainer + " card"}>
      <div ref={sentinelRef} className={styles.sentinelDiv}></div>
      {hits.map((hit: any, key: number) => {
        const lowerinter = lowerdQueryWords.filter(
          (x) => hit.interchange && !!hit.interchange.filter((i: string) => i.toLowerCase() === x)[0]
        )[0];
        const inter = queryWords.filter((x) => x.toLowerCase() === lowerinter)[0];
        var fitmentAr = [];
        //priority goes to selected machine
        const fitmentLower = hit.fitment.map((x: string) => x.toLowerCase());
        const fitsSelectedMachine = fitmentLower.filter((x: string) => x === LowerSelectedMachine).length > 0;
        if (fitsSelectedMachine) {
          fitmentAr.push(selectedMachine);
        }

        //second goes to models in query
        const fitQueryModels = hit.model
          .map((x: string) => x.toLowerCase())
          .filter((x: string) => lowerdQueryWords.filter((m) => m === x).length > 0);

        if (fitQueryModels.length > 0) {
          const fullNames = hit.fitment.filter(
            (x: string) =>
              fitQueryModels.filter((fm: string) =>
                //this forces only the last words to be checked
                //prevents manufacturers from causing a highlight
                (x.toLowerCase() + "!").includes(` ${fm}!`)
              ).length > 0
          );
          fitmentAr = fitmentAr.concat(fullNames);
        }

        //third goes to machines in fleet
        const fitsFleet = fleetContext?.fleet
          ?.map((x: any) => MachineName(x))
          .filter((x) => fitmentLower.filter((fl: string) => fl === x.toLowerCase()).length > 0);
        if (fitsFleet) {
          fitmentAr = fitmentAr.concat(fitsFleet);
        }

        // removes duplicates
        let fitment = "";
        if (fitmentAr.length > 0) {
          fitment = Distinct(fitmentAr).join(", ");
        } else fitment = "";

        return (
          <div
            key={key}
            onClick={() => {
              sendEvent("click", hit, "SelectedOnSearch");
            }}
            className={styles.seperator}
          >
            <SearchResultItem item={Assimilate(hit)} interchange={inter} fitment={fitment} />
          </div>
        );
      })}
    </div>
  );
}

export function Assimilate(hit: any): IProductSearchResult {
  const assimilatedItem: IProductSearchResult = {
    approvedRatingSum: 0,
    additionalShippingCharge: 0,
    id: hit.objectID,
    name: hit.title,
    seoName: hit.title.split(" ").join("-").replaceAll("/", "").toLocaleLowerCase(),
    partInterchange: [],
    priceAndAvailability: {
      allowedQuantities: null,
      backorderAvailabilityRange: null,
      canNotify: true,
      oldPrice: null,
      orderMinimumQuantity: 0,
      orderMaximumQuantity: 0,
      preorderAvailabilityStartDateTime: null,
      price: hit.price,
      status: hit.stock,
      stock: null,
    },
    manufacturerPartNumber: null,
    manufacturers: [{ manufacturer: { name: hit.brand } }],
    isFreeShipping: false,
    picture: {
      thumbnail: {
        url: hit.picture,
      },
    },
    warehouse: {
      address: {
        city: hit.city,
        stateProvince: {
          abbreviation: hit.state,
        },
      },
    },
  };
  return assimilatedItem;
}
