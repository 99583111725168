import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchBox } from "react-instantsearch";
import { useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import QueryString from "../../helpers/querystring";
import AutoComplete from "./AutoComplete/AutoComplete";
import style from "./AutoComplete/AutoComplete.module.scss";
import SearchBar from "./SearchBar";

export type BorderType = "shadow" | "fine";

export default function AutoCompleteSearchBar(props: { hideDesktopSuggest?: boolean; borderType?: BorderType }) {
  const history = useHistory();
  const globalContext = useContext(GlobalContext);
  const { refine, query } = useSearchBox();
  const location = useLocation();
  const [selected, setSelected] = useState(-1);
  const [searchIt, setsearchIt] = useState(-1);
  const [inputValue, setInputValue] = useState(query);
  const mobileSuggest = globalContext.isMobileSuggestOpen;
  const setMobileSuggest = globalContext.toggleMobileSuggest;
  const itemMax = 10;
  const ref = useRef<HTMLDivElement>(null);

  // Keep input value in sync with URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlQuery = params.get(`${process.env.REACT_APP_ALGOLIA_MAIN_INDEX}[query]`) || "";
    if (urlQuery !== inputValue) {
      setInputValue(urlQuery);
      refine(urlQuery);
    }
  }, [location.search, refine]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSelected(-1);
    setInputValue(value);
    refine(value);
  };

  const deselect = () => {
    setsearchIt(-1);
    setSelected(-1);
  };

  const onPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp") {
      if (selected > -1) {
        setSelected(selected - 1);
      }
    } else if (e.key === "ArrowDown") {
      if (selected < itemMax) {
        setSelected(selected + 1);
      }
    } else if (e.key === "Enter") {
      if (mobileSuggest === true) {
        SearchSelected(e.currentTarget.value);
      } else if (selected > -1) {
        setsearchIt(selected);
        setMobileSuggest(false);
      } else {
        GoToSearch();
      }
      e.currentTarget.blur();
    }
  };

  const GoToSearch = () => {
    if (location.pathname !== "/search") {
      history.push({
        pathname: GenerateLink.ForSearchProduct(),
        search: QueryString.stringify({
          [`${process.env.REACT_APP_ALGOLIA_MAIN_INDEX}[query]`]: inputValue,
        }),
      });
    }
  };

  const SearchSelected = (term: string) => {
    setInputValue(term);
    refine(term);

    if (location.pathname !== "/search") {
      history.push({
        pathname: GenerateLink.ForSearchProduct(),
        search: QueryString.stringify({
          [`${process.env.REACT_APP_ALGOLIA_MAIN_INDEX}[query]`]: term,
        }),
      });
    }
    setMobileSuggest(false);
    deselect();
  };

  const highLightItem = (index: number) => {
    setSelected(index);
  };

  const goToProductPage = (id: any, seoName: any) => {
    history.push(GenerateLink.ForProduct(id, seoName));
    setMobileSuggest(false);
  };

  const clickMobileInput = () => {
    setMobileSuggest(true);
    setTimeout(() => {
      window.scrollTo(0, -1);
    }, 110);
  };

  var sty = "";
  if (mobileSuggest) {
    sty = style["mobileShow"];
  } else {
    sty = style["mobilehide"];
  }

  return (
    <div ref={ref} className={`${style["wrapper"]}  ${sty} ${props.borderType ? style[props.borderType] : "shadow"} `}>
      <div className={style.BarBody}>
        <button
          className={style.BackButton + " image-LeftChevron"}
          onClick={(e) => {
            setMobileSuggest(false);
            e.currentTarget.blur();
          }}
        />
        <SearchBar
          style={style["SearchBar"]}
          Desktop={{
            style: style["desktopSearch"],
            onInput: onInput,
            onKeyPress: onPress,
            value: inputValue,
          }}
          Mobile={{
            onInput: onInput,
            onKeyPress: onPress,
            onClick: clickMobileInput,
            style: style["mobileSearch"],
            value: inputValue,
          }}
        />
      </div>
      <AutoComplete
        hideDesktopSuggest={props.hideDesktopSuggest}
        goToProductPage={goToProductPage}
        highLightItem={highLightItem}
        searchIt={searchIt}
        itemMax={itemMax}
        productAmount={4}
        deselect={deselect}
        selected={selected}
        SearchSuggestion={SearchSelected}
      />
    </div>
  );
}
