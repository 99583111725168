import * as React from "react";
import styles from "./SearchBar.module.scss";
interface SearchInput {
  defaultValue?: string;
  value?: string;
  onClick?: () => void;
  AutoFocus?: boolean;
  AutoComplete?: boolean;
  ref?: React.MutableRefObject<HTMLInputElement | null>;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  style?: string;
}
interface props {
  style?: string;
  Mobile?: SearchInput;
  Desktop?: SearchInput;
  Button?: {
    OnClick?: () => void;
  };
}

const SearchBar = (props: props) => {
  return (
    <div className={props.style ? props.style + " " + styles.SearchBar : styles.SearchBar}>
      <button onClick={props?.Button?.OnClick} />
      <input
        autoComplete="off"
        className={props.Desktop?.style ? props.Desktop.style + " " + styles["desktopSearch"] : styles["desktopSearch"]}
        value={props.Desktop?.value ?? props.Desktop?.defaultValue ?? ""}
        onKeyPress={props.Desktop?.onKeyPress}
        autoFocus={props.Desktop?.AutoFocus}
        onChange={props.Desktop?.onInput}
        ref={props.Desktop?.ref}
        type="search"
        name="search"
      />
      <input
        autoComplete="off"
        className={props.Mobile?.style ? props.Mobile.style + " " + styles["mobileSearch"] : styles["mobileSearch"]}
        value={props.Mobile?.value ?? props.Mobile?.defaultValue ?? ""}
        autoFocus={props.Mobile?.AutoFocus}
        onKeyPress={props.Mobile?.onKeyPress}
        onChange={props.Mobile?.onInput}
        onClick={props.Mobile?.onClick}
        onFocus={props.Mobile?.onFocus}
        onBlur={props.Mobile?.onBlur}
        ref={props.Mobile?.ref}
        type="search"
        name="search"
      />
    </div>
  );
};

export default SearchBar;
