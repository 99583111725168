import React, { useState, useEffect, useRef } from "react";
import styles from "./CategorySection.module.scss";
import GenerateLink from "../../helpers/GenerateLink";
import Belts from "../../images/part_type/belt.png";
import Filter from "../../images/part_type/filter.png";
import Glass from "../../images/part_type/glass.png";
import Radiator from "../../images/part_type/radiator.png";
import SealKit from "../../images/part_type/sealkit.png";
import Seat from "../../images/part_type/seat.png";
import Zbox from "../../images/logo/Zbox-logo.png";
import Caterpillar from "../../images/brands/caterpillar.png";
import Volvo from "../../images/brands/volvo.png";
import JohnDeere from "../../images/brands/johndeere.png";
import Case from "../../images/brands/case.png";
import Ford from "../../images/brands/ford.png";
import BushHog from "../../images/brands/bushhog.png";
import MasseyFerguson from "../../images/brands/masseyferguson.png";
import NewHolland from "../../images/brands/newholland.png";
import Bobcat from "../../images/brands/bobcat.png";
import RulerContext from "../../contexts/RulerContext";

const CategorySection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const autoScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastManualScrollTime = useRef<number>(Date.now());
  const ruler = React.useContext(RulerContext);
  const visibleItems = ruler.xs ? 1 : ruler.sm ? 2 : 5;

  const categories = [
    { src: Seat, title: "Seat", link: GenerateLink.ForSearch("Seats") },
    { src: Glass, title: "Glass", link: GenerateLink.ForSearch("Glass") },
    { src: SealKit, title: "Seal Kits", link: GenerateLink.ForSearch("Seal Kits") },
    { src: Belts, title: "Belts", link: GenerateLink.ForSearch("Belts") },
    { src: Filter, title: "Filters", link: GenerateLink.ForSearch("Filters") },
    { src: Radiator, title: "Radiators", link: GenerateLink.ForSearch("Radiator") },
  ];

  const manufacturers = [
    { src: Zbox, title: "Zbox", link: GenerateLink.ForSearch("Zbox") },
    { src: JohnDeere, title: "John Deere", link: GenerateLink.ForSearch("John Deere") },
    { src: Caterpillar, title: "Caterpillar", link: GenerateLink.ForSearch("Caterpillar") },
    { src: NewHolland, title: "New Holland", link: GenerateLink.ForSearch("New Holland") },
    { src: Volvo, title: "Volvo", link: GenerateLink.ForSearch("Volvo") },
    { src: Case, title: "Case", link: GenerateLink.ForSearch("Case") },
    { src: BushHog, title: "Bush Hog", link: GenerateLink.ForSearch("Bushhog") },
    { src: MasseyFerguson, title: "Massey Ferguson", link: GenerateLink.ForSearch("Massey Ferguson") },
    { src: Bobcat, title: "Bobcat", link: GenerateLink.ForSearch("Bobcat") },
    { src: Ford, title: "Ford", link: GenerateLink.ForSearch("Ford") },
  ];

  useEffect(() => {
    if (ruler.xs && autoScroll) {
      const startAutoScroll = () => {
        autoScrollTimeoutRef.current = setTimeout(() => {
          if (Date.now() - lastManualScrollTime.current >= 2500) {
            setCurrentIndex((prevIndex) => {
              const nextIndex = prevIndex + visibleItems;
              return nextIndex >= categories.length ? 0 : nextIndex;
            });
          }
          startAutoScroll();
        }, 2500);
      };

      startAutoScroll();

      return () => {
        if (autoScrollTimeoutRef.current) {
          clearTimeout(autoScrollTimeoutRef.current);
        }
      };
    }
    return;
  }, [ruler.xs, autoScroll, categories.length, visibleItems]);

  const nextSlide = () => {
    lastManualScrollTime.current = Date.now();
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + visibleItems;
      return nextIndex >= categories.length ? 0 : nextIndex;
    });
  };

  const prevSlide = () => {
    lastManualScrollTime.current = Date.now();
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - visibleItems;
      return nextIndex < 0 ? prevIndex : nextIndex;
    });
  };

  return (
    <>
      <div className={styles.featuredProductContainer}>
        <div className={styles.featuredProductHeader}>
          <h3 className={styles.featuredProductTitle}>FEATURED PRODUCTS</h3>
          {ruler.xs && (
            <button className={styles.autoScrollToggle} onClick={() => setAutoScroll(!autoScroll)}>
              {autoScroll ? "⏸" : "▶"}
            </button>
          )}
        </div>
        <div className={styles.carousel}>
          <div className={styles.carouselInner} style={{ transform: `translateX(-${currentIndex * (100 / visibleItems)}%)` }}>
            {categories.map((category, index) => (
              <div key={index} className={styles.card} style={{ flexBasis: `${100 / visibleItems}%` }}>
                <a href={category.link}>
                  <div className={styles.cardImage}>
                    <img src={category.src} alt={category.title} className={styles.categoryImage} />
                    <div className={styles.overlay}>
                      <div className={styles.cardTitle}>{category.title}</div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <button className={styles.arrow} onClick={prevSlide}>
            ❮
          </button>
          <button className={styles.arrow} onClick={nextSlide}>
            ❯
          </button>
        </div>
      </div>
      <div className={styles.topBrandsContainer}>
        <div className={styles.halfSection}>
          <div className={styles.manufacturerSection}>
            <h3>OUR TOP FITMENTS</h3>
            <div className={styles.brandCarousel}>
              <div className={styles.brandCategoryWrapper}>
                {manufacturers.map((manufacturer, index) => (
                  <div key={index} className={styles.brandcard}>
                    <a href={manufacturer.link}>
                      <div className={styles.brandCardImage}>
                        <img src={manufacturer.src} alt={manufacturer.title} className={styles.brandImage} />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySection;
