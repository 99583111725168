import type {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";
import connectAutocomplete from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";
import { useConnector } from "react-instantsearch";
import { Distinct } from "../../../helpers/Array";
import style from "./AutoComplete.module.scss";

export type UseAutocompleteProps = AutocompleteConnectorParams;

export default function AutoComplete(props: {
  hideDesktopSuggest?: boolean;
  itemMax: number;
  productAmount: number;
  deselect?: () => void;
  highLightItem?: (index: number) => void;
  goToProductPage: (id: any, seoName: any) => void;
  SearchSuggestion: (e: string) => void;
  searchIt?: number;
  selected?: number;
}) {
  const { indices } = useAutocomplete();
  const index = indices[0];
  const lastSuggestionIndex = props.itemMax - props.productAmount;

  // Only show suggestions if we have hits
  if (!index?.hits?.length) {
    return null;
  }

  return (
    <div className={style["container"] + " " + (props.hideDesktopSuggest ? style["desktopHide"] : "")}>
      <div className={style["fullWidth"]}>
        <div className={style["dropdown"]}>
          {Distinct(
            index.hits
              .map((x) => {
                if (x.SearchTerm) {
                  return x.SearchTerm;
                }
                const ar = [];
                if (x.category) ar.push(x.category);
                if (x.part) ar.push(x.part);
                if (x.interchange) {
                  ar.push(...x.interchange);
                }
                if (x.fitment) {
                  ar.push(...x.fitment);
                }
                return ar;
              })
              .flat()
              .filter(Boolean) // Remove any undefined/null values
          )
            .slice(0, lastSuggestionIndex)
            .map((x: any, i: number) => {
              const term = x;
              const index = i;
              if (props.searchIt === index) {
                props.SearchSuggestion(term);
              }
              return (
                <div
                  key={index}
                  onMouseEnter={() => props.highLightItem && props.highLightItem(index)}
                  onClick={() => props.SearchSuggestion(term)}
                  className={style["item"] + " " + (index === props.selected ? style["selected"] : null)}
                >
                  <div>{term}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export function useAutocomplete(props?: UseAutocompleteProps) {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(connectAutocomplete, props);
}
