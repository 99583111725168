import { BaseHit } from "instantsearch.js/es/types";
import { ReactNode, useEffect } from "react";
import { Configure, InstantSearch, useHits, useSearchBox } from "react-instantsearch";
import { getSearchClient } from "../../pages/algolia_search/algoliaClient";
import { ProductMaster } from "../../pages/algolia_search/Results";
import Collection, { ICollectionData, ICollectionLayout } from "./Collection";

interface IAlgoliaCollection {
  query: string;
  layout: ICollectionLayout;
  displayAmount: number | null;
  title: ReactNode;
  seeMoreTo: string;
  filters?: string;
}

const AlgoliaCollection = (props: IAlgoliaCollection) => {
  console.log("AlgoliaCollection", props);
  return (
    <InstantSearch searchClient={getSearchClient()} indexName={process.env.REACT_APP_ALGOLIA_MAIN_INDEX ?? ""}>
      <Configure clickAnalytics={true} userToken="zbox" filters={props.filters} />
      <AlgoliaCollectionChild
        seeMoreTo={props.seeMoreTo}
        title={props.title}
        displayAmount={props.displayAmount}
        query={props.query}
        layout={props.layout}
      ></AlgoliaCollectionChild>
    </InstantSearch>
  );
};

const AlgoliaCollectionChild = (props: IAlgoliaCollection) => {
  const { refine, query } = useSearchBox();

  useEffect(() => {
    refine(props.query);
  }, [props.query, refine]);

  const { hits } = useHits<BaseHit & ProductMaster>();
  const res = hits.slice(0, props.displayAmount ?? 5).map((x) => {
    return {
      id: x.objectID,
      name: x.title,
      seoName: x.seoName,
      approvedRatingSum: 0,
      approvedTotalReviews: 0,
      picture: {
        thumbnail: {
          url: x.picture,
        },
      },
      priceAndAvailability: {
        canNotify: true,
        orderMinimumQuantity: 1,
        orderMaximumQuantity: 1,
        preorderAvailabilityStartDateTime: "",
        price: x.price,
        status: x.stock,
        stock: x.quantity,
      },
    };
  }) as ICollectionData[];

  return query !== props.query ? null : (
    <Collection seeMoreTo={props.seeMoreTo} title={props.title} layout={props.layout} products={res}></Collection>
  );
};

export default AlgoliaCollection;
